<template>
  <div>
    <v-container>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลพื้นฐาน"
        class="px-5 py-3"
      >
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pa-1">
            <v-card
              class="elevation-6"
              style="border-radius: 10px; border: solid 2px green"
            >
              <v-card-text>
                <v-layout justify-center align-center>
                  <v-flex>
                    <v-card class="elevation-0">
                      <v-card-text class="text-xs-left pl-5">
                        <div>ชื่อสถานศึกษา</div>

                        <div class="pl-4 grey--text">
                          {{ collegeinfo.collegeinfo_code }} :
                          {{ collegeinfo.collegeinfo_code }}
                          {{ collegeinfo.college_name }}
                        </div>
                      </v-card-text>
                      <v-card-text class="text-xs-left pl-5">
                        <div>ที่อยู่</div>
                        <div class="pl-4 grey--text">
                          <span>{{ collegeinfo.collegeinfo_address }}</span>
                        </div>
                      </v-card-text>
                      <v-card-text class="text-xs-left pl-5">
                        <div>โทรศัพท์</div>
                        <div class="pl-4 grey--text">
                          {{ collegeinfo.collegeinfo_phone }}
                        </div>
                      </v-card-text>
                      <v-card-text class="text-xs-left pl-5">
                        <div>โทรสาร</div>
                        <div class="pl-4 grey--text">
                          {{ collegeinfo.collegeinfo_fax }}
                        </div>
                      </v-card-text>
                      <v-card-text class="text-xs-left pl-5">
                        <div>E-mail</div>
                        <div class="pl-4 grey--text">
                          {{ collegeinfo.collegeinfo_email }}
                        </div>
                      </v-card-text>

                      <v-card-text class="text-xs-left pl-5">
                        <div>Line Token Key</div>
                        <div class="pl-4 grey--text">
                          {{ collegeinfo.college_tokenkey }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="pr-2 mb-2">
                <v-spacer></v-spacer>
                <v-btn
                  color="yellow darken-3"
                  dark
                  rounded
                  @click.native="collegeinfoEditmain()"
                >
                  <v-icon small dark>mdi-pencil</v-icon>&nbsp;แก้ไข</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" class="pa-1">
            <v-card
              class="elevation-6"
              style="border-radius: 10px; border: solid 2px green"
            >
              <v-card-text>
                <v-layout justify-center align-center>
                  <v-flex>
                    <v-card class="elevation-0">
                      <v-card-text class="text-xs-left pl-5">
                        <!--   <img :src="'/HRvecfiles/'+collegeinfo.collegeinfo_directorpic" height="300" v-if="collegeinfo.collegeinfo_directorpic" style="border-radius: 30px">
                     -->
                        <div class="text-center">
                          <img
                            :src="
                              'https://hrvec.ovec.go.th/HRvecfiles/' +
                                collegeinfo.collegeinfo_directorpic
                            "
                            height="200"
                            v-if="collegeinfo.collegeinfo_directorpic"
                            style="border-radius: 30px"
                          />
                          <v-btn
                            @click.stop="deletefiledialog2 = true"
                            color="red darken-3"
                            v-if="collegeinfo.collegeinfo_directorpic"
                            dark
                            icon
                            outlined
                          >
                            <v-icon small>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </v-card-text>
                      <v-card-text class="text-xs-left pl-5">
                        <div>ชื่อ - สกุล ผู้อำนวยการวิทยาลัย</div>
                        <div class="pl-4 grey--text">
                          <span>{{ collegeinfo.collegeinfo_director }}</span>
                        </div>
                        <div>
                          <span
                            class="success--text"
                            v-if="collegeinfo.collegeinfo_director_type === '0'"
                            >โดยตำแหน่ง</span
                          >
                          <span
                            class="warning--text"
                            v-else-if="
                              collegeinfo.collegeinfo_director_type === '1'
                            "
                            >รักษาการในตำแหน่ง</span
                          >
                        </div>
                      </v-card-text>
                      <v-card-text class="text-xs-left pl-5">
                        <div>โทรศัพท์</div>
                        <div class="pl-4 grey--text">
                          {{ collegeinfo.collegeinfo_directorphone }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="pr-2 mb-2">
                <v-spacer></v-spacer>
                <v-btn
                  color="yellow darken-3"
                  @click.native="collegeinfoEditpic()"
                  dark
                  rounded
                >
                  <v-icon small dark>mdi-pencil</v-icon>&nbsp;แก้ไข</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </base-material-card>
    </v-container>

    <!-- แก้ไขข้อมูล -->
    <v-layout row justify-center>
      <v-dialog v-model="collegeinfodialog1" persistent max-width="80%">
        <v-card class="mx-auto pa-5">
          <base-material-card
            color="yellow"
            icon="mdi-clipboard-text"
            title="แก้ไขข้อมูลพื้นฐาน"
            class="px-5 py-3 text_google"
          ></base-material-card>
          <v-card-text>
            <v-form ref="form1" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      label="ชื่อสถานศึกษา"
                      required
                      v-model="collegeinfo.college_name"
                      :rules="[v => !!v || '']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      label="ที่อยู่"
                      v-model="collegeinfo.collegeinfo_address"
                      textarea
                      rows="10"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      label="โทรศัพท์"
                      v-model="collegeinfo.collegeinfo_phone"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      label="โทรสาร"
                      v-model="collegeinfo.collegeinfo_fax"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field
                      label="E-mail"
                      v-model="collegeinfo.collegeinfo_email"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-text-field
                      label="Line Token Key"
                      v-model="college_update.college_tokenkey"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <small>* จำเป็น</small>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop="collegeinfodialog1 = false" rounded
              ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
            >
            <v-btn
              color="success"
              @click.stop="editcollegeinfoSubmit1()"
              rounded
            >
              <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- ข้อมูลผู้อำนวยการ -->
    <v-layout row justify-center>
      <v-dialog v-model="collegeinfodialog5" persistent max-width="80%">
        <v-card class="mx-auto pa-5">
          <base-material-card
            color="yellow"
            icon="mdi-clipboard-text"
            title="แก้ไขข้อมูลผู้อำนวยการวิทยาลัย"
            class="px-5 py-3 text_google"
          ></base-material-card>

          <v-card-text>
            <v-form ref="form5" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md6>
                    เลือกไฟล์ภาพผู้อำนวยการวิทยาลัย
                    <input type="file" ref="file2" id="file2" />
                  </v-flex>
                  <v-flex md6>
                    <v-radio-group
                      v-model="collegeinfo.collegeinfo_director_type"
                      row
                    >
                      <template v-slot:label>
                        <div><strong>ผู้อำนวยการวิทยาลัย</strong></div>
                      </template>
                      <v-radio value="1">
                        <template v-slot:label>
                          <div>
                            <strong class="warning--text"
                              >รักษาการ (ไม่มี ผอ.)</strong
                            >
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="0">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text"
                              >โดยตำแหน่ง (มี ผอ.)</strong
                            >
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex md6>
                    <v-autocomplete
                      v-model="collegeinfo.collegeinfo_director"
                      :items="personnel_temporarys"
                      item-text="frist_names"
                      item-value="frist_names"
                      label="เลือก"
                      :rules="[v => !!v || '']"
                      outlined
                    >
                      <template v-slot:item="{ item }">
                        <div class="pa-2">
                          <div class="font-weight-black">
                            {{ item.frist_names }}
                          </div>
                          <div>
                            {{ item.position_name }} : {{ item.college_name }}
                          </div>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      label="เบอร์โทรศัพท์"
                      v-model="collegeinfo.collegeinfo_directorphone"
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop="collegeinfodialog5 = false" rounded
              >ยกเลิก</v-btn
            >
            <v-btn
              color="success"
              @click.stop="editcollegeinfoSubmit()"
              rounded
            >
              <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- ลบรูปภาพ -->
    <v-layout row justify-center>
      <v-dialog v-model="deletefiledialog2" persistent max-width="50%">
        <v-card>
          <v-card-title class="red darken-3 white--text elevation-2">
            <v-icon medium dark>fa-school</v-icon
            >&nbsp;ลบข้อมูลไฟล์รูปผู้อำนวยการ
          </v-card-title>
          <v-card-text>
            <v-form ref="deletestandardtopicform2" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 class="text-xs-center red--text">
                    ยืนยันการลบไฟล์รูปผู้อำนวยการ
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="deletefiledialog2 = false" rounded
              >ยกเลิก</v-btn
            >
            <v-btn
              color="red darken-3"
              @click.native="deletefileSubmit2()"
              rounded
              dark
            >
              <v-icon dark small>mdi-delete</v-icon>&nbsp;ลบ</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    collegeinfodialog1: false,
    collegeinfodialog2: false,
    collegeinfodialog3: false,
    collegeinfodialog4: false,
    collegeinfodialog5: false,
    deletefiledialog: false,
    deletefiledialog2: false,
    snackbar: {
      show: false,
      color: "",
      timeout: 5000,
      icon: "",
      text: ""
    },
    ApiKey: "HRvec2021",
    college: {},
    collegeinfo: [],
    editcollegeinfo: {},
    period_year: "",
    user_update: {},
    college_update: {},
    data_syslog: {},
    personnel_temporarys: []
  }),
  async mounted() {
    await this.collegeQuery();
    await this.collegeinfoQuery();
    await this.personnel_temporaryQueryAll();
  },

  methods: {
    async personnel_temporaryQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          province_ID: userSession.user_province_ID
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;   
    },

    async collegeQuery() {
      let result = {};
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("college.php", {
        college_code: userSession.user_code,
        ApiKey: this.ApiKey
      });
      this.college = result.data;
    },
    async collegeinfoQuery() {
      let result = await this.$http.post("collegeinfo.php", {
        ApiKey: this.ApiKey,
        collegeinfo_code: this.college.college_code
      });
      this.collegeinfo = result.data;
    },

    async collegeinfoEditmain() {
      this.collegeinfodialog1 = true;
    },

    async collegeinfoEditpic() {
      this.collegeinfodialog5 = true;
    },

    async editcollegeinfoSubmit1() {
      if (this.$refs.form1.validate()) {
        this.collegeinfo.ApiKey = this.ApiKey;
        /*  */
        this.user_update.ApiKey = this.ApiKey;
        this.user_update.user_code = this.collegeinfo.college_code;
        this.user_update.user_firstname = this.collegeinfo.college_name;
        this.user_update.user_director = this.collegeinfo.collegeinfo_director;
        this.user_update.user_director_type = this.collegeinfo.collegeinfo_director_type;

        /*  */
        this.college_update.ApiKey = this.ApiKey;
        this.college_update.college_code = this.collegeinfo.college_code;
        this.college_update.college_name = this.collegeinfo.college_name;
        let result = await this.$http.post(
          "collegeinfo.update.php",
          this.collegeinfo
        );

        let result_user = await this.$http.post(
          "user.update.code.php",
          this.user_update
        );

        let result_college = await this.$http.post(
          "college.update.code.php",
          this.college_update
        );

        if (
          result.data.status == true ||
          result_user.data.status == true ||
          result_college.data.status == true
        ) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.collegeinfoQuery();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.collegeinfoQuery();
        }
        this.collegeinfodialog1 = false;
      }
    },

    async editcollegeinfoSubmit() {
      let result = "";
      let uploaded = null;

      if (this.$refs.file2.files[0]) {
        if (this.$refs.file2.files[0].type == "image/jpeg") {
          let formData = new FormData();
          let filename =
            this.collegeinfo.collegeinfo_code +
            "." +
            this.time_stamp +
            "." +
            this.period_year_bd +
            ".directorpic.jpg";
          formData.append("file", this.$refs.file2.files[0]);
          formData.append("filename", "../HRvecfiles/" + filename);
          formData.append("ApiKey", this.ApiKey);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          this.collegeinfo.collegeinfo_directorpic = "";
          this.collegeinfo.collegeinfo_directorpic = filename;

          uploaded = true;
        } else {
          uploaded = false;
        }
      }

      this.collegeinfo.ApiKey = this.ApiKey;
      result = await this.$http.post(
        "collegeinfo.update.php",
        this.collegeinfo
      );

 

      this.user_update.ApiKey = this.ApiKey;
      this.user_update.user_code = this.collegeinfo.college_code;
      this.user_update.user_firstname = this.collegeinfo.college_name;
      this.user_update.user_director = this.collegeinfo.collegeinfo_director;
      this.user_update.user_director_type = this.collegeinfo.collegeinfo_director_type;

      let result_user = await this.$http.post(
        "user.update.code.php",
        this.user_update
      );

      if (result.data.status == true || result_user.data.status == true) {
        await this.collegeinfoQuery();
        this.snackbar.icon = "mdi-content-save";
        this.snackbar.color = "success";
        this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
        this.snackbar.show = true;

        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "update_img";
        this.data_syslog.page_log = "collegeinfo";
        this.data_syslog.table_log = "collegeinfo,user,college";
        this.data_syslog.detail_log = this.collegeinfo.collegeinfo_name;
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        if (!uploaded) {
          this.snackbar.icon = "mdi-alert";
          this.snackbar.color = "red";
          this.snackbar.text =
            "ไฟล์ที่แนบไม่ถูกต้อง กรุณาแนบไฟล์เป็น jpg หรือ jpeg เท่านั้น";
          this.snackbar.show = true;
        } else {
          this.snackbar.icon = "mdi-alert";
          this.snackbar.color = "red";
          this.snackbar.text = "บันทึกข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
      }

      this.collegeinfodialog1 = false;
      this.collegeinfodialog2 = false;
      this.collegeinfodialog3 = false;
      this.collegeinfodialog4 = false;
      this.collegeinfodialog5 = false;
    },

    async deletefileSubmit2() {
      let result = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename: "../HRvecfiles/" + this.collegeinfo.collegeinfo_directorpic
      });

      result = await this.$http.post("collegeinfo.update.php", {
        ApiKey: this.ApiKey,
        collegeinfo_directorpic: "",
        period_year: this.period_year,
        collegeinfo_code: this.collegeinfo.collegeinfo_code
      });

      this.user_update.ApiKey = this.ApiKey;
      this.user_update.user_code = this.collegeinfo.college_code;
      this.user_update.user_directorpic = "";

      let result_user = await this.$http.post(
        "user.update.code.php",
        this.user_update
      );

      if (result.data.status == true || result_user.data.status == true) {
        this.collegeinfoQuery();
        Swal.fire({
          icon: "success",
          title: "ลบข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });

        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "delete_img";
        this.data_syslog.page_log = "collegeinfo";
        this.data_syslog.table_log = "collegeinfo,user,college";
        this.data_syslog.detail_log = this.collegeinfo.collegeinfo_name;
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        Swal.fire({
          icon: "warning",
          title: "ลบข้อมูลผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }

      this.deletefiledialog2 = false;
    }
  },
  computed: {
    period_year_bd() {
      let today = new Date();
      let year = today.getFullYear() + 543;
      return year;
    },
    time_stamp() {
      const d = new Date();
      let time = d.getTime();
      return time;
    },

    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  },
  watch: {
    async period_year_bd(newVal, oldVal) {
      await this.collegeinfoQuery();
    }
  }
};
</script>
